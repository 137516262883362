<template>
    <div class="aj-collected-papers">
        <HeaderAnnualMeeting />
        <div style="height: 400px;">
            <HeaderAJ />
        </div>
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>
        <div class="aj-collected wid1200">
            <div class="left-img">
                <div class="img-wrap">
                    <!-- <img src="../assets/img/book.png" alt="" /> -->
                    <img :src="bookObj.imgPath" alt="" />
                </div>
            </div>
            
            <div class="info-wrap">
                <ul>
                    <li>
                        <h4>集刊名称</h4>
                        <p>{{bookObj.name}}</p>
                        <h4>所属学科</h4>
                        <p>{{bookObj.subject}}</p>
                    </li>
                    <li>
                        <h4>主办单位</h4>
                        <p>{{bookObj.insNames}}</p>
                        <h4>主编</h4>
                        <p>{{bookObj.author}}</p>
                    </li>
                    <li>
                        <h4>创刊时间</h4>
                        <p>{{bookObj.firstDate}}</p>
                        <h4>已出版辑数</h4>
                        <p>{{bookObj.count}}</p>
                    </li>
                    <li class="publisher">
                        <h4>出版单位</h4>
                        <p>{{bookObj.publisher}}</p>
                    </li>
                    <li class="abstract">
                        <h4>集刊简介</h4>
                        <p class="abstract-msg" v-html="bookObj.description"></p>
                    </li>
                    <li class="contributed-articles">
                        <h4>稿约</h4>
                        <div  class="contributed-articles-msg" v-html="bookObj.notice"></div>
                    </li>
                </ul>
              
              
            </div>
        </div>
    </div>
</template>

<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import HeaderAJ from '../components/HeaderAJ'
import BreadCrumb from '../components/BreadCrumb'
export default {
    data () {
        return {
            pN: '',
            pageName: '集刊详情页',
            bookObj: {}
        }
    },
    components: { HeaderAnnualMeeting, HeaderAJ, BreadCrumb },
    methods: {
        getData() {
            let _this = this
            this.$http({
                method: 'get',
                url: ' /admin/api/cp/detail?idOrName='+this.$route.query.id
            }).then(res=> {
                if (res.data.code == 0) {
                    var x = res.data.data
                    x.description = '<p>' + x.description.replace(/\r\n/g, "</p><p>") +'</p>'
                    this.bookObj = x
                }
            })
        }
    },
    created () {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.aj-collected-papers {
    .aj-collected {
        display: flex;
        .left-img {
            .img-wrap {
                padding: 20px 35px;
                border: 1px solid rgba(153, 153, 153, 0.4);
                margin-right: 8px;
                img {
                    display: block;
                    width: 180px;
                    height: auto;
                    height: 256px;
                }
            }
        }
        
        .info-wrap {
            width: 940px;
            ul {
                li {
                    display: flex;
                    margin-bottom: 4px;
                    h4 {
                        width: 140px;
                        margin-right: 4px;
                        color: #FFFFFF;
                        font-size: 16px;
                        background: #C30D23;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    p {
                        width: 324px;
                        font-size: 16px;
                        color: #0D1020;
                        line-height: 20px;
                        padding: 15px 14px;
                        box-sizing: border-box;
                        border: 1px solid rgba(153,153,153,0.4);
                        text-align: left;
                    
                    }
                    p:nth-child(2) {
                        margin-right: 4px;
                    }
                }
                li.publisher {
                    p {
                        flex: 1;
                        margin-right: 0;
                    }
                }
                li.abstract {
                    p {
                        flex: 1;
                        margin-right: 0;
                        height: 300px;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                li.contributed-articles {
                    .contributed-articles-msg {
                        flex: 1;
                        margin-right: 0;
                        height: 704px;
                        border: 1px solid rgba(153, 153, 153, 0.4);
                        padding: 15px 14px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
</style>